@font-face {
    font-family: 'soleil_light';
    src: url('soleil/SoleilLight.otf');
}

@font-face {
    font-family: 'soleil_regular';
    src: url('soleil/SoleilRegular.otf');
    /* font-weight: bold; */
}

@font-face {
    font-family: 'soleil_medium';
    src: url('soleil/SoleilBook.otf');
    font-weight: bold;
}

@font-face {
    font-family: 'soleil_bold';
    src: url('soleil//SoleilBold.otf');
    /* font-weight: bold; */
}