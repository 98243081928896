:root {
    --primary: #009d92;
    --secondary: #dcae27;
    --light: #f4f4f4;
    --light-primary: #b7edda;
    --light-gray: #c5c5c5;
    --light-orange: #eb9739;
    --white: #fff;
    --gray: #808080;
    --gray2:#939393;
    --black: #000;
    --dark-gray:#4b4d4c;
    --light-black :#444;
    --light-black2:#333;
    --light-primary: #b2f9f4;
    --light-violet:#e5eeee;
  }