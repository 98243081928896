@import url('./color.css');
@import url('./font.css');

* {

    font-family: "soleil_regular";
}

.ff_regular {
    font-family: "soleil_regular";
}

.ff_bold {
    font-family: 'soleil_bold';
}

.ff_light {
    font-family: "soleil_light" !important;
}

.primary_color {
    color: var(--primary) !important;
}

.light_color {
    color: var(--dark-gray);
}

.second_color {
    color: var(--secondary);
}

.bg_primary {
    background-color: var(--primary);
}

.color_white {
    color: var(--white);
}

.Nav1 {
    position: relative;
    font-size: 0.8rem;
    width: auto;
    /* padding-left: 7.5%; */
    color: var(--light-gray);
    background-color: transparent;
}

.Nav1~.sub {
    display: none;
}

@media screen and (min-width:4100px) {
    .Nav2~.sub {
        right: 61% !important;

    }
}

@media screen and (min-width:2800px) and (max-width:4100px) {
    .Nav2~.sub {
        right: 66% !important;

    }
}

@media screen and (min-width:2200px) and (max-width:2800px) {
    .Nav2~.sub {
        right: 73% !important;

    }
}

.Nav2~.sub {
    color: var(--primary);
    padding: 1px 0 2px;
    content: "";
    margin-top: -1px;
    position: absolute;
    background-color: var(--primary);
    right: 82%;
    left: 0;
    z-index: -1;
}

.brush {
    position: relative;
    z-index: inherit;
    display: flex;
    align-items: center;
    color: var(--white);
    width: auto;
    margin-top: 4.8rem;
    /* padding-left: 2rem; */
    background-image: url('../assets/images/brush.png');
    background-position: -2%;
    /* background-size: 50%; */
    background-repeat: no-repeat;
    height: 4.5vh;
}

.Nav2 {

    width: auto;
    color: var(--white);
    display: flex;
    font-size: 0.8rem;
}

.Nav2 .bg_color {
    /* padding-left: 7.5%; */
    padding-left: 10px;
    padding-top: 4px;
    padding-right: 4px;
    padding-bottom: 4px;
    background-color: var(--primary);

}

.Nav1 .c_img {
    display: none;
}

.Nav2 .c_img {
    /* padding-left: 2px; */
    margin-left: -2px;
    background-color: transparent !important;
    height: 27px;
    max-width: 100%;
}

.NavB1 {
    transition: .2s ease-in-out;
    background-color: transparent;
    padding-bottom: 0px;
    padding-top: 1rem;
}

.NavB1 .logo1 {
    display: none;
}

.logo_size2,
.NavB1 .logo_size {
    height: 80px;
    width: 110;
}
.logo_size3{
    height: 90px;
    width: 120;
}

.NavB .logo_size {
    height: 60px;
}

.NavB .logo_size2 {
    height: 70px;
}

.NavB1 .img1 {
    color: var(--white);
}

.NavB1 .call_btn {
    display: none !important;
    padding: 8px 10px;
    border-radius: 5rem;
    border: none;
    background-color: var(--primary);
}

.NavB {
    padding-top: 0px;
    transition: .2s ease-in-out;
    padding-bottom: 0px;
    background-color: var(--primary);
}

.NavB .logo2 {
    display: none;
}

.NavB .img1 {
    color: var(--primary);
}

.NavB .call_btn {
    padding: 5px 10px;
    border-radius: 5rem;
    border: none;
    background-color: var(--white);
}

.nav_top {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: .2s ease-in-out;
}

.chn_text::after {
    width: 100%;
    background-color: white;
}

.NavB .q_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1vh;
    border: none;
    background-color: white;
    color: var(--secondary);
}

.NavB1 .q_btn {
    display: none !important;
}

/* ************************ */
.bg-img {
    position: relative;
    height: 98vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('../assets/images/bg.jpg');
}

.bg-img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.5;
}

.inner {
    text-align: center;
    position: relative;
    z-index: inherit;
    line-height: 1.2;
    display: grid;
    height: 98vh;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-size: 40px;
    font-family: 'soleil_bold' !important;
    font-weight: 600;
    justify-items: center;
    align-content: center;
}

.cont_btn {
    margin-top: 6vh;
    color: var(--white);
    background-color: var(--primary);
    border: none;
    border-radius: 4vh;
    padding: 1.3vh 5vh;
    font-family: 'soleil_medium';
    font-size: 22px;
}

.img_7 {
    width: 80%;
}

._cont_ {
    padding-top: 1vh;
    padding-bottom: 2vh;
    display: flex;
    margin: auto;
    width: 60%;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: var(--light-black);
}

.services_card {
    margin-top: 2vh;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    column-gap: 3vh;
    row-gap: 3vh;
}

.services_card>div {
    background: #FCFFFE;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    border-radius: 1.5vh;
    transition: 0.5s;
    justify-content: space-between;
}

.car_cont {
    background: #FCFFFE;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
    border-radius: 1.5vh;
    transition: 0.5s;
}

.car_cont:hover,
.services_card>div:hover {
    cursor: pointer;
    transition: 0.5s;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);

}

.car_cont .carousel-inner {
    border-radius: 1.5vh;
}

.card_img {
    width: 100%;
    object-fit: cover;
    border-top-right-radius: 1.5vh;
    border-top-left-radius: 1.5vh;
}

.card_cont {
    position: relative;
    border-top-right-radius: 1.5vh;
    border-top-left-radius: 1.5vh;
}

.card_cont::before {
    border-top-right-radius: 1.5vh;
    border-top-left-radius: 1.5vh;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    transition: 0.6s;
    opacity: 0.5;
}

.card_cont:hover::before {
    transition: 0.6s;
    opacity: 0.6;

}

.inner_cont {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: var(--white);
    font-weight: 700;
    font-size: 20px;
    font-style: italic;
}

.card_discp {
    text-align: left;
    margin-top: 2vh;
    padding: 2vh 2.3vh;
    color: var(--light-black2);
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
}

.card_btn {
    display: flex;
    margin-left: auto;
    margin-right: 1rem;
    margin-bottom: 1rem;
    background-color: transparent;
    color: var(--primary);
    border-color: 1px solid var(--primary);
    border-radius: .8vh;
    padding: .8vh 1.8vh;
    font-size: 13px;
}

.card_btn:focus,
.card_btn:active,
.card_btn:hover {
    box-shadow: none !important;
    background-color: var(--primary) !important;
    border-color: 1px solid var(--primary) !important;
    color: var(--white) !important;
}

.check_card {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    column-gap: 3vh;
    row-gap: 9vh;


    /* position: absolute; */
    /* z-index: 2000; */
    /* margin-top: -40px; */
}

.img2 {
    width: 100%;
    /* max-height: 100%; */
    height: 24vh;
    object-fit: cover;
}

.bg_colr {
    position: relative;
    /* background-color: var(--white); */
    transition: .2s ease-in-out;
    /* height: 100vh !important; */
    padding-bottom: 4.4rem;
}

.card1 {
    position: relative;
    margin-top: -50px;
    box-shadow: 5px 5px 20px -10px rgba(0, 0, 0, .3) !important;
    transition: .2s ease-in-out;
}

.card2 :hover {
    box-shadow: 10px 5px 20px -10px rgba(0, 0, 0, .3) !important;
}

.card_con {
    height: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    transition: .2s ease-in-out;


}

.border_col {
    transition: .2s ease-in-out;
    border-bottom: 4px solid var(--primary);
}

.outerdiv.border_col {
    transition: .2s ease-in-out;
    border-bottom: 4px solid var(--secondary) !important;
}

.card_con .form-check-input[type=checkbox] {
    width: 30px;
    height: 30px;
    transition: .2s ease-in-out;
    border-radius: 50% !important;
}

.card_con .form-check-input[type=checkbox]:checked {
    transition: .2s ease-in-out;
    background-color: var(--secondary) !important;

}

.card_con .form-check-input[type=checkbox]:checked:focus,
.card_con .form-check-input:checked,
.card_con .form-check-input:focus {
    transition: .2s ease-in-out;
    border: none;
    box-shadow: 0 0 0 0.25rem rgba(220, 174, 39, 0.25);
}

.b_list {
    font-size: 0.9rem;

}

.b_list>div {
    display: flex;
    align-items: center;
    margin-top: 1.5vh;
}

.tick_img {
    margin-right: 10px;
    width: 15px;
}

.d_sliver {
    width: 60px;
}

.req_btn {
    border: none;
    background-color: var(--secondary);
    color: var(--white);
    width: 100%;
    display: flex;
    justify-content: center;
}

.carousel-indicators {
    bottom: -70px;
}

.car_cont .carousel-control-next,
.car_cont .carousel-control-prev {
    width: auto !important;
    opacity: 1 !important;
}


.carousel-control-next,
.carousel-control-prev {
    width: auto !important;
    opacity: 1 !important;
}

.carousel-indicators [data-bs-target] {
    background-color: var(--primary);
    width: 23px;
    height: 8px;
    border-top: 0;
    border-bottom: 0;
    border-radius: 1vh;
    margin-left: 9px;
}

.carousel-control-prev-icon {
    display: none;
}

.carousel-control-next-icon {
    display: none;
}

.carousel_img {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.carousel_img_size {
    width: 49%;
    object-fit: cover;
    object-position: center;
    height: 47vh;
}

.carousel_con {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 48%;
    height: 47vh;
}

.cont_grid {

    /* margin-top: 2rem; */
    display: grid;
    grid-template-columns: 3fr 7fr;
}

.discrip {
    font-family: "soleil_light";
    padding: 0 1.4rem;
    font-size: 1.1rem;
    border-left: 1px solid var(--light-gray);
    text-align: justify;
    display: flex;
    align-items: flex-end;
}

.price {
    padding-right: 1.4rem;
    color: var(--gray);
    display: flex;
    align-items: flex-end;
    font-size: 1.2rem;
}

.roler_img {
    width: 30px;
}

.services {
    margin-top: 7.5rem;
    background: var(--light);
    display: flex;
    padding: 28px 0;
    box-shadow: 5px 5px 20px -5px rgba(0, 0, 0, .3);
    flex-wrap: nowrap;
    font-size: 18px;

}

#allservices {
    background-image: url('../assets/images/bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.btn_close {
    display: none !important;
}

.show_btn .btn_close {
    display: flex !important;
    position: absolute;
    right: 0;
    top: 0;
    width: 28px;
    border-radius: 100%;
    height: 28px;
    color: var(--primary);
    margin: 4px;
}

.all_serv {
    margin-top: 3rem;
    height: 700px;
    width: 100%;
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
}

.services__content {
    padding: 2rem 5vw;
    width: 100%;
    font-size: 18px;
    line-height: 1.5;
    display: flex;
    position: relative;
    z-index: 0;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: top center;
    color: #fff;
}

.services__content .richtext {
    position: relative;
    z-index: 3;
}

.richtext h2 {
    font-family: "soleil_bold";
    line-height: 1.5;
    font-size: 30px;
}

.richtext p {
    font-size: 18px;
    line-height: 1.5;
}

.tab-content {
    display: none;
}

.tab-content.active {

    display: block;
}

.show_btn .tab-btn.active {
    background-color: var(--primary);
    color: var(--white);
}

.tab-btn.active {
    background-color: var(--primary);
    color: var(--white);
}

.overlay {
    position: absolute;
    z-index: -4;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .4);
}

.usp__item {

    text-align: center;
    width: 50%;
    flex: 1;
    padding: 0 15px;
}

.left_border {
    border-left: 1px solid var(--light-gray);
}

.usp__icon {
    margin: 0 auto 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 65px;
    background: #fff;
    border-radius: 100%;
    box-shadow: 5px 5px 20px -5px rgba(0, 0, 0, .3);
    color: var(--primary);
    font-size: 38px;
    line-height: 1.5
        /* padding-top: 2px; */
}

.head2 {
    margin-top: 6rem;
    /* font-size: 40px; */
    line-height: 1.2;
    font-family: 'soleil_bold';
    color: var(--primary);
    text-align: center;
}

.show_btn .services__boxes {
    width: 96%;
    display: grid;
    column-gap: 1px;
    row-gap: 1px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    padding: 0 1px 1px 0;
    background-color: transparent;
    margin: 20px;
    transition: .6s ease-in-out, .6s ease-in-out !important;
}

.services__boxes {
    transition: 0.8s !important;
    width: 69%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0 1px 1px 0;
    background-color: #dedede;
    margin: 20px;
}

.services__icon {
    font-size: 60px;
    line-height: 1;
    font-weight: 400;
    color: var(--primary);
    transition: color .2s ease-in-out;
}

.show_btn .services__item {
    width: 100%;
    margin: 0;
    border: none;
    overflow: hidden;
    /* margin-left: 1px;
    margin-top: 1px; */
    background-color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #000;
    transition: background-color .2s ease-in-out, color .2s ease-in-out;
    font-size: 14px;
}

.services__item {

    margin: 0;
    border: none;
    width: calc(50% - 1px);
    height: 160px;
    overflow: hidden;
    margin-left: 1px;
    margin-top: 1px;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #000;
    transition: background-color .2s ease-in-out, color .2s ease-in-out;
    font-size: 14px;
}

.btn_white {
    background-color: var(--white);
    color: var(--primary);
    border: 1px solid var(--white);
    padding: 15px 30px;
    border-radius: 1vh;
}

.btn_white2 {
    background-color: var(--white);
    color: var(--primary);
    border: 3px solid var(--white);
    border-radius: 1vh;
    padding: 14px;
}

.cus_btn {
    border: none;
    display: flex;
    justify-content: center;
    font-size: 0.9rem;
    align-items: center;
}

.fs_9 {
    font-size: 0.9rem;
}

.btn1 {
    background-color: transparent;
    color: var(--white);
    border: 3px solid var(--light);
    border-radius: 1vh;
    padding: 14px 0px;
}

.btn_width {
    width: 200px;
}

.cont_block_para p {
    text-align: justify;
    font-size: 1.15rem;
    line-height: 1.5;
    font-family: "soleil_regular";
}

@media screen and (max-width:768px) {
    .cont_block_para p {
        text-align: center;
    }
    
}
.loc {
    background-color: transparent;
    padding: 12px 10px;
    border-radius: 1vh;
    border: 3px solid var(--white);
    width: 220px;
}

.loc option {
    background-color: var(--primary);
}

.common:hover {
    color: var(--white);
    opacity: 0.8;
    transition: 0.5s;
}

.btn_white2:hover,
.btn1:hover,
.btn_white:hover {
    opacity: 0.8;
    transition: 0.5s;
}

.show_btn .hide_btn {
    display: none;
}

.show_service {
    display: none;
}

.active .services__icon {
    color: var(--white) !important;
}

.cont_block {
    /* font-size: 40px; */
    font-family: "soleil_bold";
    line-height: 1.2;
    margin: 0 0 10px;
    color: var(--primary);
}

.footer_top {
    color: var(--white);
    padding: 1rem;
    font-size: 1rem !important;
}

.footer_top .social_icon {
    color: var(--white);
    font-size: 2rem;
    margin: 0 0.4rem;
}

.social_icon:hover {
    opacity: 0.8;
}

.a-href {
    text-decoration: none;
}

.footer_bottom {
    background-color: var(--light-black);
    color: var(--white);
    padding: 2.7rem 0 0;
    font-size: 1.1rem;
}

.footer_bottom ul {
    list-style: none;
    padding-left: 0;
}

.footer_end {
    background-color: var(--light-black2);
}

.color_gray {
    color: #989494;
}

.add_info {
    background-color: var(--light-violet);
    color: var(--light-black);
    font-family: 'soleil_light';
    font-size: 22px;
    padding: 15px 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.add_icon {
    margin-left: -9px;
    color: var(--light-violet);
    font-size: 1.9rem;
}

.service_info {
    display: flex;
    align-items: center;
}

.additional_info .accordion-item:first-of-type,
.additional_info .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.additional_info .collapse.show {
    background-color: var(--light-violet);
}

.additional_info .accordion-item {
    margin-bottom: 0.9rem !important;
    border: none !important;
}

.accordion-item {
    margin-bottom: 0.9rem !important;
    border-radius: 1.5vh !important;
}

.accordion-item:first-of-type,
.accordion-item:first-of-type .accordion-button {
    border-radius: 1.5vh !important;
    /* border-top-right-radius: 1.5vh !important; */
}

.accordion-button:not(.collapsed) {
    background-color: var(--light);
    border-bottom: 0px !important;
}

.accordion-item:last-of-type .accordion-button.collapsed,
.accordion-item:last-of-type {
    border-bottom-right-radius: 1.5vh !important;
    border-bottom-left-radius: 1.5vh !important;
}

.accordion-button {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
}

.accordion-button:focus {
    border-color: transparent;
    box-shadow: none;
}

.additional_info .accordion-item:last-of-type .accordion-button.collapsed,
.additional_info .accordion-item:last-of-type {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.additional_info .accordion-button {
    font-family: "soleil_light";
    font-size: 1.05rem;
    padding: 20px;
    background-color: var(--light);
}

.additional_info .accordion-button:not(.collapsed) {
    box-shadow: none !important;
    color: var(--primary);
    background-color: var(--light-violet) !important;
}

.additional_info .accordion-body {
    color: var(--light-black2);
    font-family: "soleil_light";
    background-color: var(--light-violet) !important;
}

.show_btn .services__icon {
    font-size: 60px;
    line-height: 1;
    font-weight: 400;
    color: var(--primary);
    transition: color .2s ease-in-out;
}

.form-select,
.form-control {
    border-radius: 0.7vh;
    color: var(--gray);
    font-size: 0.9rem;
}

._line_ {
    color: var(--white);
    margin: 10px 0;
    font-size: 0.7rem;
    border-radius: 4rem;
    padding: 0.5rem 0.8rem;
    background-color: var(--light-black);

}

._line_2 {
    color: var(--white);
    margin: 10px 0;
    font-size: 0.7rem;
    border-radius: 4rem;
    padding: 0.5rem 0.8rem;
    background-color: var(--primary);

}

.hr_line {
    width: 100%;
    background-color: var(--light-gray);
    height: 1px;
}

.hr_line_set {
    display: grid;
    grid-template-columns: 2fr 1fr 10fr;
    align-items: center;
}

.req_modal {
    height: 70vh;
    overflow-y: auto;
}

.req_modal input::placeholder {
    font-weight: 300 !important;
    font-family: 'soleil_light';
}

.form-check-input:checked {
    border-color: var(--primary) !important;
    background-color: var(--primary) !important;
}

.form-control:focus,
.form-select:focus,
.form-check-input:focus {
    border-color: var(--light-primary);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(75, 205, 196, 0.25);
}

.clean_req {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    justify-content: space-evenly;
    column-gap: 1vh;
}

.react-tel-input .form-control {
    width: 100% !important;
}

.modalpic {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
}

.form-check-label {
    font-family: "soleil_light";
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.accordion-header {
    position: relative !important;
    z-index: 0;
}

.pic_limit {
    font-size: 0.8rem;
    font-family: "soleil_light";
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 5px 8px;
}

.pic_clean {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    column-gap: 1vh;
    grid-auto-rows: 1fr;
    row-gap: 2vh;
}

.modal {
    z-index: 99999;
}

.pic_clean img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.contact_Us>h6 {
    margin: 15px 10px;
}



.cookyModal .modal-dialog {
    max-width: 95% !important;
}

@media screen and (min-width:768px) {
    .cookyModal {
        top: 25% !important;
    }
}
@media screen and (max-width:767.98px) {
    /* .cookyModal {
        top: 10% !important;
    } */
}
.react-tel-input .form-control:required,
input:required {
    border: 1px solid #ccc;
}

.react-tel-input .form-control:required:invalid,
input:required:invalid {
    border: 1px solid #ccc;
}

.react-tel-input .form-control:required:valid,
input:required:valid {
    border: 1px solid green;
}

.toast.show {
    position: absolute !important;
    /* bottom: 0 ; */
    top: 2%;
    z-index: 1030;
    right: 2%;
}

.about_list ul>li,
.about_list ol>li {
    margin-top: 10px;
    margin-bottom: 10px;
}

/* .name_address .row>*{

} */
@media screen and (max-width: 500px) {
    .services_card {
        grid-template-columns: 0.8fr;
        justify-content: center;
        place-items: center
    }

    .carousel_con {
        width: 100% !important;
    }

    .check_card {
        place-items: center;
    }

    .services__boxes {
        width: 95% !important;
    }
}

@media screen and (max-width: 400px) {
    .services_card {
        grid-template-columns: 1fr !important;
    }
}

@media (max-width: 1100px) {
    ._cont_ {
        width: 98%;
        margin-bottom: 1.6rem;
    }

    /* .services__content {
        padding: 0 630px 0 100px;
    } */
}

@media screen and (max-width:992px) {
    .show_btn .services__item {
        height: 130px;
    }

    .show_btn .services__boxes {

        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)) !important;
    }

    .show_btn .services__icon {
        font-size: 40px !important;
    }

    .d_lg_none {
        display: none !important;
    }

    .inner {
        font-size: 34px;
        width: 100%;
    }

    .all_serv {
        flex-direction: column;
        height: auto;
    }

    .services {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
        column-gap: 3vh;
        row-gap: 5vh;

    }

    .carousel_img_size {
        width: auto;
    }

    .carousel_img {
        display: block;
    }

    .carousel_img_size {
        height: auto;
    }

    .carousel_con {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1.2rem;
        width: 100%;
        text-align: center;
    }

    .usp__item {
        width: auto;
    }

    .footer_change {
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 768px) {
    .card_none {
        display: none !important;
    }

    .inner {
        font-size: 2.5rem;
    }

    .services__icon {
        font-size: 50px !important;
    }

    .carousel_con {
        height: auto;
    }

    .footer_change2 {
        justify-content: center;
        text-align: center;
    }
}

@media (min-width: 768px) {
    .car_none {
        display: none !important;
    }

    .footer_change2 {
        justify-content: end;
    }
}

@media (min-width: 980px) {

    .show_btn .services__item:not(.edit):hover,
    .services__item:not(.edit):hover {
        background-color: var(--light-primary);
        color: var(--black);
    }

    .usp__icon {
        margin-bottom: 15px;
    }
}

@media screen and (max-width:768px) {
    .cont_btn {
        font-size: 18px !important;
    }

    .service_info {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .add_icon {
        margin-right: 0px !important;
        margin-top: -10px;
        transform: rotate(90deg);
    }

    .d_none {
        display: none;
    }

    .circle_1 {
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 100% !important;
        display: flex !important;
        align-items: center !important;
    }
}

@media screen and (max-width: 1400px) {
    .discrip {
        font-size: 0.9rem !important;
    }

    .fs_1 {
        font-size: 2rem;
    }

    .cont_grid {
        grid-template-columns: 4fr 6fr;

    }
}

@media screen and (min-width: 1400px) {

    .fs_1 {
        font-size: 2.3vw;
    }
}

@media screen and (max-width:1200px) {
    .all_serv.show_btn {
        height: auto !important;
    }


    .d_xl_none {
        display: none;
    }

    .carousel_con {
        justify-content: space-evenly;
    }

    .d_center {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .cont_grid {
        grid-template-columns: 1fr;

    }
}
a:hover{
    text-decoration: none !important;
}
.primary_color{
    color:var(--primary)
}